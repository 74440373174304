export const TRANSLATIONS_FR = {
	select_file: 'Selectionnez un document',
	welcome_msg: "Bienvenue à l'Outil de Contrôle Interne",
	joined_groups: 'Groupes rejoints',
	department: 'Département',
	project: 'Projet',
	name: 'Nom',
	file_upload: 'Importer Fichier',
	upload_file_to: 'Importer Fichier a',
	create_check: 'Creer un contrôle',
	select_xlsx_file_and_drag:
		'Sélectionnez le fichier .xlsx ou faites-le glisser ici',
	check_date_period: "Date d'envoi et Periode",
	period: 'periode',
	month: 'mois',
	set_send_date_and_period: "Choisir Date d'envoi et Periode",
	save: 'enregistrer',
	update: 'Mettre a jour',
	set_period: "Choisir periode d'envoi",
	set_date: "Choisir date d'envoi",
	future_date_error: 'SVP choisir une date future',
	cell: 'Cellule',
	mission: 'Mission',
	filter_by: 'Filtrer par',
	checks: 'Contrôles',
	submitted_checks: 'Contrôles soumis',
	pending_checks: 'Contrôles en attente',
	overdue_checks: 'Contrôles non envoyes',
	of_targets_hit: 'objectifs a atteindre',
	the_overall_performance: 'Performance globale',
	keep_track: 'Veuillez faire le suivi des resultats globaux',
	you_have_submitted: 'vous avez soumis',
	hello: 'Bonjour',
	view_answers: 'Visualiser reponses',
	you_have_answered: 'vous avez repondu',
	questions: 'questions',
	receive_date: 'Date recue',
	send_date: "Date d'envoi",
	status: 'Statut',
	view: 'visualiser',
	submit_date: 'Date de soumission',
	project_code: 'code projet',
	check: 'Contrôle',
	check_form: 'Formulaire de contrôles',
	archived: 'archivé',
	all: 'tous',
	sent: 'envoye',
	received: 'recu',
	overdue: 'Delais depasse',
	link: 'Lien',
	click_here: 'cliquez ici',
	to_review_your_answers: 'visualiser vos reponses',
	recently_have_submitted: 'Vous avez récemment soumis',
	one: 'un',
	pending: 'en attente',
	completed: 'completes',
	to_complete_submitting_checks:
		'pour terminer la soumission de vos contrôles mensuels',
	you_have: 'Vous avez',
	is: 'est',
	are: 'sont',
	corrective_measures: 'Mesures correctives',
	the_score_is: 'Le resultat est',
	priority: 'Priorité',
	corrective_measures_thanks: "merci d'avoir envoye vos mesures correctives",
	corrective_measures_form: 'formulaire de mesures correctives',
	form_error_msg: "s'assurer que tous les champs sont remplis",
	submitted_corrective_measures: 'soumis des mesures correctives',
	score: 'Score',
	submit_corrective_measures: 'soumettre des mesures correctives',
	corrective_measures_required: 'mesures correctives requises',
	the_function_in_charge: 'la fonction responsible',
	due_date: "Date d'échéance",
	low: 'bas',
	received_checks: 'contrôles recus',
	medium: 'moyen',
	high: 'eleve',
	all_missions: 'toutes les missions',
	all_cells: 'toutes les cellules',
	all_projects: 'tous les projets',
	all_departments: 'tous les departements',
	from: 'de',
	to: 'a',
	corrective_measures_score: 'resultats mesures correctives',
	graph: 'graph',
	year: 'annee',
	num_of_received_checks: '# de contrôles recus',
	num_of_submitted_checks: '# de contrôles soumis',
	num_of_pending_checks: '# de contrôles en attente',
	num_of_overdue_checks: '# de contrôles dont le delais est depasse',
	num_of_sent_checks: '# de contrôles envoyes',
	january: 'Janvier',
	february: 'Février',
	march: 'Mars',
	april: 'Avril',
	may: 'Mai',
	june: 'Juin',
	july: 'Juillet',
	august: 'Août',
	september: 'Septembre',
	october: 'Octobre',
	november: 'Novembre',
	december: 'Decembre',
	sent_checks: 'contrôles envoyes',
	submit: 'Soumis',
	home: 'Accueil',
	manage_projects: 'Gérer des projets',
	upload: 'télécharger',
	sign_out: 'Se déconnecter',
	sign_in: 'Se connecter',
	group_name: 'nom de groupe',
	create_group: 'créer un groupe',
	assign_projects_to_groups: 'Attribuer des projets à des groupes',
	add_groups_to_checks: 'Ajouter des groupes aux contrôles',
	back_home: 'Retour à la maison',
	email: 'e-mail',
	out_of_office: 'En dehors du bureau',
	manage_out_of_office: "Gérer l'absence du bureau",
	manage_users: 'Gérer les utilisateurs',
	canceled: 'Annulé',
	impact: 'Impact',
	person_in_charge: 'Personne en charge',
	in_charge: 'en charge',
	corrective_measures_link: 'Lien mesures correctives',
	sample: 'Échantillon',
	monthly_pending_checks: 'Contrôles mensuels en attente',
	project_summary: 'Résumé du projet',
	mission_summary: 'Résumé de la mission',
	summary_comment_text: 'Veuillez ajouter un commentaire ici pour résumer',
	summary_comment: 'commentaire de synthèse',
	about_title: 'À Propos',
	about_text_1:
		"L’Outil de Contrôle Interne (WeCheck) est un exercice d'auto-évaluation basé sur un ensemble de contrôles prédéfinis à effectuer régulièrement sur tous les projets et dans toutes les missions par tous les responsables de département (Approvisionnement, Logistique, Finance, Ressources Humaines, Médical et Opérations).",
	about_text_2:
		'À travers cet exercice, les superviseurs des départements contrôlent si les procédures ou processus de base validés par OCB sont correctement et systématiquement appliqués dans leurs départements. C’est donc un outil important pour la gestion des projets par les managers responsables de département et le coordinateur de projet.',
	label: 'label',
	category: 'Catégorie',
	last_send_date: 'date du dernier envoi',
	timing: 'Horaire',
	project_comment: 'commentaire du projet',
	mission_comment: 'commentaire de mission',
	period_checks: 'contrôles périodique',
	monthly_completed_checks: 'Contrôles effectués mensuellement',
	Support: 'Support',
	average_Q1: 'moyenne T1',
	average_Q2: 'moyenne T2',
	average_Q3: 'moyenne T3',
	average_Q4: 'moyenne T4',
	clear_filter: 'effacer le filtre',
	department_not_present: 'département non présent',
	results_by_department_month: 'Résultats par département par mois',
	results_by_project_month: 'Résultats par projet par mois',
	avg_quarter_by_department: 'Résultats trimestriels moyens par département',
	avg_quarter_by_project: 'Résultats trimestriels moyens par projet',
	evolution_avg_quarterly_by_department:
		'Évolution des résultats trimestriels moyens par département',
	evolution_avg_quarterly_by_project:
		'Évolution des résultats moyens par trimestre et par projet',
	list_of_cm: 'Liste des mesures correctives',
	checks_are_out: 'les contrôles sont maintenant disponibles',
	here: 'ici',
	checks_home_page_msg:
		"N'oubliez pas de compléter votre suivi mensuel avant la fin du mois en cliquant sur",
	checks_are_still_pending: 'les contrôles sont toujours en attente',
	great_job: 'Bravo',
	checks_have_been_submitted: 'les chèques ont été déposés',
	present: 'présent',
	not_present: 'pas présent',
	departments_checks_results_by_month:
		'Résultats des contrôles du département par mois',
	submitted: 'Soumise',
	comment: 'Commentaire',
	checks_of_month: 'les contrôles de mois de',
	have_been_completed: 'on bien été complétés',
	error: 'Erreur',
	access_error: "Vous essayez d'accéder à WeCheck en utilisant cette email",
	screenshot_msg: "Veuillez prendre une capture d'écran et l'envoyer à",
	change_request:
		"Si vous souhaitez demander une modification / suppression / ajout d'un des chèques, veuillez contacter",
	following_info: 'avec les informations suivantes',
	your_mission: 'Votre mission',
	your_project: 'Votre projet',
	your_department: 'Votre département',
	change_question: 'Question que vous souhaitez modifier',
	contextual_reason: 'Raison contextuelle',
	techincal_contact:
		'Si vous rencontrez des problèmes techniques, veuillez contacter',
	view_questions: 'Afficher les questions',
	modify: 'modifier',
	success_request_msg: 'Votre demande a été soumise avec succès!',
	non_inOfficeDate_msg: `Remarque : Si vous ne souhaitez pas définir de date de retour au bureau, veuillez cliquer sur Effacer après avoir défini "à partir de la date" comme le montre la capture d'écran ci-dessous, puis cliquez sur Enregistrer.`,
	mark_ooo_msg: `Ce projet montrera que l'ensemble de votre département n'est actuellement pas disponible, donc aucun chèque ne sera envoyé à votre département jusqu'à ce qu'il soit à nouveau disponible.`,
	send: 'Envoyer',
	no_user_found:
		"Il n'y a aucun utilisateur affecté à ce projet ou à ce département",
	send_now: 'Envoyer Maintenant',
	user: 'Utilisateur',
	send_check_error:
		"Une erreur s'est produite lors de l'envoi du contrôle, veuillez réessayer plus tard ou contacter l'administrateur",
	send_check_success_msg: 'Le contrôle a été envoyé avec succès',
	wait: "S'il vous plaît, attendez!",
	powerbi_report: 'Rapport PowerBI',
	no_user_assigned_department_project:
		"Aucun utilisateur n'est affecté à ce département dans ce projet",
}
